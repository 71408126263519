import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The sun sets behind the mountains on the other end of the Fjord. We are
flying down the flowy trails above the tree line on the Haugsvarden ridge.
The trail feels as it grew out of the landscape. Like it would already exist
for thousands of years. This is of course not true. A group of enthusiastic
local bikers around Pål Kristian Gimmestad, put in thousands of hours to dig
the trails of Sandane, move rocks, and even carve them out of the bedrock.`}</p>
    <TrailguidePlugin content="lat=61.8009&lng=6.2395" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=12,61.8009,6.2395"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Haugsvarden`}</h4>
    <p>{`One of the most scenic rides you will find in Norway. It is summer, the sun
sets very late and I can join Dag, Torgeir and Øyvind for an after work ride.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/5342"
      }}>{`here`}</a></p>
    <Image src="destinations/sandane_7.jpg" mdxType="Image" />
    <br />
    <p>{`After a nice climb on the trail, we push our bikes all the way to the top.
We can see far into the high mountains of the Jostedalsbreen National park.
There the snow doesn't even melt in summer.`}</p>
    <Image src="destinations/sandane_2.jpg" mdxType="Image" />
    <br />
    <p>{`The descent begins fast and rocky. With this view it is hard to keep the
eyes on the trail.`}</p>
    <Image src="destinations/sandane_3.jpg" mdxType="Image" />
    <br />
    <p>{`The terrain soon becomes less steep. The flowy trail follows the wide ridge.
Sandane lies far below us, at the end of the Fjord.`}</p>
    <Image src="destinations/sandane_4.jpg" mdxType="Image" />
    <br />
    <p>{`Slowly we come down into the trees. The trail starts to wind around them in
awesome turns. You can feel the trail builders are good bikers. There is
plenty of roots and grippy dirt.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/5342"
      }}>{`here`}</a></p>
    <Image src="destinations/sandane_8.jpg" mdxType="Image" />
    <br />
    <h4>{`Gloppefjorden`}</h4>
    <p>{`Back down in Sandane, summer is in full swing. The fjord is shallow and the
water is warm. Everyone enjoys the warm temperatures and plays in the water.
For a moment we forget that we are far north and not in Italy.`}</p>
    <Image src="destinations/sandane_9.jpg" mdxType="Image" />
    <br />
    <h4>{`Blånibba`}</h4>
    <p>{`Today Vidar from `}<a parentName="p" {...{
        "href": "https://www.nordfjordaktiv.no"
      }}>{`Nordfjord Aktiv`}</a>{`
shows us his home ground. Our second tour takes us even higher up.
After a steep climb on a gravel road, a long push bike starts. We work
our way up to the peak of Blånibba. From here we can see the Breimsvatnet
east of Sandane. To the west we see into the Fjord and the peak of
Haugsvarden from yesterdays tour. The descent starts rocky and alpine,
but with a clear line.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/5339"
      }}>{`here`}</a></p>
    <Image src="destinations/sandane_14.jpg" mdxType="Image" />
    <br />
    <p>{`The flow of the trail develops more and more. We are flying on the grassy
alpine terrain on a narrow trail. Always prepared for some sudden corners or
sharp rocks pointing out. In the background you can see the Myklebustbreen glacier.`}</p>
    <Image src="destinations/sandane_15.jpg" mdxType="Image" />
    <br />
    <p>{`The long descent on the ridge felt endless. After a steeper technical part
we come into the woods. The trail goes along a beautiful creek. A few meters
later it starts to get tricky. Lots of rocks make a good line choise and
active riding necessary to get over.`}</p>
    <Image src="destinations/sandane_16.jpg" mdxType="Image" />
    <br />
    <h4>{`Veten`}</h4>
    <p>{`We left the rough part behind and get to the Grande Finale of this long
descent. The Veten trail is a very nice mix of rocky technical and very
fast and flowy sections.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/5340"
      }}>{`here`}</a></p>
    <Image src="destinations/sandane_10.jpg" mdxType="Image" />
    <br />
    <p>{`Nice rockslabs. Open and fast trail. But be prepared for slippery
conditions when it's wet.`}</p>
    <Image src="destinations/sandane_12.jpg" mdxType="Image" />
    <br />
    <h4>{`Vereide`}</h4>
    <p>{`West of Sandane, towards the airport, there are mellow hills with a
beautiful forest and a lovely network of playful trails. The stripe
reflecting the light is the small airport of Sandane. Ferries crossing
over to Lote, the connection to the next biggest village Nordfjordeid.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/5336"
      }}>{`here`}</a></p>
    <Image src="destinations/sandane_18.jpg" mdxType="Image" />
    <br />
    <h4>{`Baron`}</h4>
    <p>{`The trails around Vereide are easier, wind in awesome turns through the
open forest and the blueberry bushes. You can connect them in an endless
number of possibilities. After a short climb, a new descent is waiting.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/5337"
      }}>{`here`}</a></p>
    <Image src="destinations/sandane_13.jpg" mdxType="Image" />
    <br />
    <h4>{`Vereide`}</h4>
    <p>{`Dag joins us for an afterwork ride again. It often rains at the west coast.
But these trails dry up very quickly.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/5336"
      }}>{`here`}</a></p>
    <Image src="destinations/sandane_17.jpg" mdxType="Image" />
    <br />
    <h4>{`Sti Center`}</h4>
    <p>{`Also at Vereide, is the new Trail Center. It is a nice loop with an easy
uphill and a fun descent. Many berms and nice corners, waves, and little
jumps. The trail is very smooth with hardly any obstacles, so it is very
well suited for kids and beginners.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/5341"
      }}>{`here`}</a></p>
    <Image src="destinations/sandane_1.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      